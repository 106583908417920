export default Object.freeze({
    FiveStarGeneral: 1,
    FiveStarSeasonal: 2,
    LegendaryMythicEmblem: 3,
    Grail: 4,
    Rearmed: 5,
    FiveStarLegacy: 6,
    ThreeFourStar: 7,
    ThreeFourStarSeasonal: 8,
    FiveStarSeasonalLegacy: 9,
    LegendaryMythicRemixed: 10,
    Attuned: 11,
    Aided: 12,
    LegendaryRevival: 13
})