import { NavLink } from "react-router-dom";
import { isInCollection, isInWishlist } from "../../helpers/collectionHelper";
import { useCollection, useDispatch, useSettings } from "../../reducer/hooks";
import HeroInfo from "../../types/HeroInfo";
import Tick from "../Tick";
import WishlistButton from "../WishlistButton";

import "./HeroGridTile.scss";

type HeroGridTileProps = {
  hero: HeroInfo;
};

const HeroGridTile = ({ hero }: HeroGridTileProps) => {
  const { obtainedArt, obtainedStyle, unobtainedArt, unobtainedStyle } =
    useSettings();
  const { collection, wishlist } = useCollection();
  const dispatch = useDispatch();

  const checkboxChanged = (shortId: string) => {
    const isIn = isInCollection(shortId, collection);

    dispatch({
      type: !isIn
        ? "ADD_HEROES_TO_COLLECTION"
        : "REMOVE_HEROES_FROM_COLLECTION",
      shortIds: [shortId],
    });
  };

  const wishlistChanged = (shortId: string) => {
    const isIn = isInWishlist(shortId, wishlist);

    dispatch({
      type: !isIn ? "ADD_HEROES_TO_WISHLIST" : "REMOVE_HEROES_FROM_WISHLIST",
      shortIds: [shortId],
    });
  };

  const iconImageRoot = `${process.env.REACT_APP_STORAGE_URL}icons`;
  const continentImageRoot = `${process.env.REACT_APP_STORAGE_URL}continents`;

  const obtained = isInCollection(hero.shortId, collection);
  const wishlisted = isInWishlist(hero.shortId, wishlist);

  if (obtained && obtainedStyle === "hide") {
    return <></>;
  }

  let itemClassName = "hero-grid__item";
  if (
    (obtained && obtainedStyle === "fade") ||
    (!obtained && unobtainedStyle === "fade")
  ) {
    itemClassName += " hero-grid__item--faded";
  }

  let imgSrc = hero.images.thumbnail[unobtainedArt];
  if (obtained) {
    imgSrc = hero.images.thumbnail[obtainedArt];
  }

  const heroDisplayName = `${hero.characterName}: ${hero.title}`;

  return (
    <div className={itemClassName} key={hero.shortId}>
      <div
        className="hero-grid__image-area"
        style={{
          backgroundImage: `url(${continentImageRoot}/${
            hero.heroGames[0]?.gameId ?? 0
          }.png)`,
        }}
      >
        <img
          className="hero-grid__thumbnail"
          src={imgSrc}
          alt={`${heroDisplayName} artwork`}
          loading="lazy"
        />
        <div className="hero-grid__icon-area">
          <div>
            <img
              className="weapon-icon"
              src={`${iconImageRoot}/weapons/${hero.heroColor.name}${hero.heroWeapon.name}.png`}
              alt={hero.heroWeapon.name}
              loading="lazy"
            />
            <br />
            <img
              className="move-type-icon"
              src={`${iconImageRoot}/movetypes/${hero.heroMoveType.name}.png`}
              alt={hero.heroMoveType.name}
              loading="lazy"
            />
          </div>
          <div>
            {!obtained && (
              <WishlistButton
                active={wishlisted}
                onChange={() => {
                  wishlistChanged(hero.shortId);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="hero-grid__info">
        <div>
          <NavLink className="hero-grid__name" to={`/Hero/${hero.shortId}`}>
            {hero.characterName}
          </NavLink>
          <br />
          <span className="hero-grid__title">{hero.title}</span>
        </div>
        <div>
          <Tick
            checked={obtained}
            onChange={() => {
              checkboxChanged(hero.shortId);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroGridTile;
